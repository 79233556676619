@import '../../../../sass/variables';
@import '../../../../sass/mixin';

.intoPading {
  .vitamins-container {
    padding-top: 50px;
    border-top: 1px solid #EEF0F4;
    padding-bottom: 50px;
    &:first-child {
      padding-top: 0;
      border-top: none; } } }
.intolerance-content {
  .vitaminsTab-content-list-img {
    color: #ff8b8b;
    background: #ffe7e7; }
  .vitaminsTab-content-list:first-child .vitaminsTab-content-list-img {
    background: #e7fff3; }
  .vitaminsTab-content-list:first-child .vitaminsTab-content-list-img span {
    color: #7fdfaf; }
  .vitaminsTab-content-list-box {
    width: 100%; }
  .d-flex {
    padding: 0;
    @include respond-to-custom(991px) {
      flex-wrap: wrap; }
    > ul {
      width: 100%;
      margin-right: 100px;
      @include respond-to-custom(1440px) {
        margin-right: 50px; }
      @include respond-to-custom(991px) {
        margin: 0;
        width: 50%;
        padding-right: 30px; }
      @include respond-to-custom(768px) {
        width: 100%;
        padding-right: 0; }
      li {
        @include respond-to-custom(991px) {
          margin-bottom: 16px; } } } } }
