@import '../sass/variables';
@import '../sass/mixin';

.button {
    display: block;
    font: 18px/1 $font-stack;
    font-weight: 600;
    color: #fff;
    background: linear-gradient(40.99deg, #1157CC 9.66%, #54A3FF 94.33%, #1157CC 195.39%);
    box-shadow: 0px 6px 13px rgba(4, 39, 90, 0.25);
    border-radius: 99px;
    padding: 20px 70px;
    transition: all 0.3s ease-out;
    margin: 0 auto;
    border: none;
    @include respond-to-custom(370px) {
        padding-left: 20px;
        padding-right: 20px;
        width: 90%; }
    &:focus, &:hover {
        cursor: pointer;
        outline: none;
        background: linear-gradient(34.15deg, #0A4DB0 11.09%, #3E8CE7 81.38%, #0E439A 186.83%);
        transform: scale(1.06); } }

