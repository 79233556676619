@import '../../sass/variables';
@import '../../sass/mixin';

@media screen and (max-width: 576px) {
  .menu-form .form-register {
    //width: 290px!important
    width: 100%;
    height: 306px!important;
    padding: 40px 30px!important; }
  .menu-form .form-register input {
    margin: 15px 0!important;
    padding: 24px 18px!important; }
  .menu-form .form-register button {
    margin-top: 15px; }
  .menu-form .form-register h5 {
    font-size: 16px!important; } }
