@import '../../../../sass/variables';
@import '../../../../sass/mixin';

.multi-risk {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #EEF0F4;
    box-sizing: border-box;
    box-shadow: 5px 8px 20px rgba(0, 0, 0, 0.03);
    border-radius: 5px;
    padding: 30px; }
