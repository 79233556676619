@import '../../../../sass/variables';
@import '../../../../sass/mixin';

.diseaseWrap {
    background-color: #fff;
    //box-shadow: 5px 8px 20px rgba(0,0,0,.03)
    //border-radius: 5px
    width: 100%;
    //padding: 50px
    .link {
        margin-top: 0; }
    .graphicTitle {
        font: 18px/1.2 $font-second;
        color: #8E8D8E; }
    &-title {
        display: flex;
        align-items: center;
        margin-top: 30px;
        .h3 {
            font: 20px/1.3 $font-second;
            font-weight: 500;
            color: $second-color;
            margin-right: 10%; }
        p {
            font: 14px/22px $font-second;
            font-weight: normal;
            color: $second-color;
            margin-bottom: 0;
            border-left: 1px solid #EEF0F4;
            padding-left: 50px;
            max-width: 720px; } }
    &-ui {
        display: flex;
        justify-content: space-between;
        padding-top: 50px;
        &-graphic {
            width: 100%;
            max-width: 48%; }
        &-desc {
            width: 100%;
            max-width: 36%;
            .vitaminsTab-content-recommendation {
                padding: 30px 40px;
                .h3 {
                    display: flex;
                    font-size: 18px; } }
            .vitaminsTab-content-list-box {
                padding-top: 40px;
                .h3 {
                    font-size: 18px;
                    font-weight: 500;
                    color: $second-color;
                    margin-bottom: 20px; } } } }
    .vitaminsTab-content-list {
        flex-wrap: wrap;
        .h3 {
            font-size: 18px;
            font-weight: 500;
            color: $second-color; }
        .d-flex {
            align-items: center; }
        .vitaminsTab-content-list-img-colorPink {
            background: #ffe7e7;
            span {
                transform: rotate(180deg);
                color: #ff8b8b; } }
        .vitaminsTab-content-list-img-colorBlue {
            background: #F0F6FF;
            span {
                transform: rotate(180deg);
                color: $primery-color; } } }
    .vitaminsTab-content-list--update {
        width: 100%;
        display: flex;
        margin-top: 40px;
        .vitaminsTab-content-list-box {
            margin-right: 50px; } }
    .border-padding {
        border-bottom: 1px solid #eef0f4;
        padding: 50px 0;
        &:last-of-type {
            border-bottom: none; } } }
