@import '../../sass/variables';
@import '../../sass/mixin';

.menu-form {
  @extend %main-content;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  .modal-header {
    position: absolute;
    top: 0;
    z-index: 1;
    right: 1px;
    border: none; }
  .form-consult {
    @extend %main-form;
    border: none;
    padding: 50px;
    width: 440px;
    height: 395px;

    h5 {
      font-weight: bold;
      font-size: 20px;
      line-height: 27px; }
    input {
      border-radius: 35px;
      padding: 30px 24px;
      margin: 20px 0; } } }

.styles_modalCenter__L9F2w {
  border-radius: 20px;
  padding: 0; }

