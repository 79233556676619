.gens-head {
    display: flex;
    border: 1px solid #EEF0F4;
    margin-top: 40px;

    > div {
        width: 80%; }
    .gens-center {
        text-align: center;
        border-bottom: 1px solid #EEF0F4;
        padding: 5px; }
    .gens-title {
        color: #8E8D8E;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500; }
    ul {
        display: flex;
        li {
            color: #1157CC;
            font-size: 14px;
            font-weight: 500;
            border-right: 1px solid #EEF0F4;
            width: 11.11%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 11px;
            text-align: center;
            &:last-child {
                border: none; } } } }
