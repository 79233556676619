@import '../../sass/variables';
@import '../../sass/mixin';

.cabinet-page {
  .main-cabinet-bg {
    background: #FAFCFF!important; }
  .main-wrapp {
    min-height: 100vh;
    .row {
      height: 100%;
      @include respond-to-custom(1096px) {
        flex-direction: column; }
      @include respond-to-custom(991px) {
        padding-top: 60px; }
      .sidebar {
        padding-top: 30px;
        box-shadow: 5px 8px 20px rgba(0, 0, 0, 0.03);
        @include respond-to-custom(1280px) {
          max-width: 100%; }
        .nav-item {
          @include respond-to-custom(1096px) {
            align-items: center !important; } }
        .img-wrapped {
          display: flex;
          flex-direction: column;
          width: 100%;
          max-width: 150px;
          align-items: center;
          position: relative;
          margin-bottom: 20px!important;
          img {
            height: 150px;
            width: 150px;
            border-radius: 100px; }
          .open-img {
            cursor: pointer;
            position: absolute;
            height: 100%;
            width: 100%;
            border-radius: 100px;
            transition: all 0.3s;
            &:hover {
              .hover-icon {
                background-color: rgba(14,22,88,0.5);
                transition: 0.3s ease-out;
                img {
                  opacity: 1; } } }
            .hover-icon {
              height: 50%;
              width: 100%;
              position: absolute;
              bottom: 0;
              background-color: rgba(14,22,88,0);
              border-radius: 0 0 100px 100px;
              align-items: center;
              display: flex;
              img {
                width: 100%;
                height: 20px;
                margin: 0 auto;
                position: absolute;
                opacity: 0; } }
            input[type=file] {
              display: none; } } } }
      .main-cont {
        background-color: #FAFCFF;
        padding: 0 50px 0 50px!important;
        min-height: 100vh;
        @include respond-to-custom(1096px) {
          max-width: 100%;
          padding: 0 !important; }
        .nawb-header {
          .title-wrapp {
            h2 {
              font-size: 24px;
              line-height: 50px;
              align-items: center;
              margin-bottom: 0;
              color: $second-color;
              font-weight: normal;
              font-family: $font-second; }
            .link-to-blog {
              display: inline-flex;
              font-size: 12px;
              p {
                color: #8E8D8E; } } } }

        .main-wrapped-cont {
          background-color: $white;
          box-shadow: 5px 8px 20px rgba(0, 0, 0, 0.03);
          border-radius: 5px;
          padding: 0  45px 50px 45px; } } } } }


.status-step {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 320px;
  .title-content {
    border-bottom: 1px solid #EEF0F4;
    margin-bottom: 50px;
    h4 {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 12px;
      line-height: 50px;
      color: $second-color;
      font-family: $font-second; } }
  .rc-steps {
    //width: 90%
    position: relative;
 }    //left: -25px

  .rc-steps-item-tail {
    padding: 0;
    top: 11px;
    box-shadow: 0 0 0 16px rgb(248, 250, 253);
    &:after {
      content: '';
      height: 10px;
      left: 20px;
      position: absolute;
      z-index: 5;
      width: 90%;
      top: -5px; } }
  .rc-steps-item-icon {
    margin-top: 1px;
    margin-left: 32px;
    height: 37px;
    width: 37px;
    line-height: 30px;
    border: none;
    position: relative;
    top: -9px;
    &:after {
      content: '';
      box-shadow: 0 0 0 14px rgb(248, 250, 253);
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      border-radius: 32px; } }


  .rc-steps-item-tail:after {
    background-color: #EEF0F4; }
  .rc-steps-item-finish .rc-steps-item-tail:after {
    background-color: $primery-color;
    left: 20px; }
  .rc-steps-dot .rc-steps-item-icon .rc-steps-icon-dot {
    border-radius: 20px; }
  .rc-steps-item-process .rc-steps-item-icon {
    background-color: $primery-color; }
  .rc-steps-item-wait .rc-steps-item-icon {
    background-color: #EEF0F4; }
  .rc-steps-item-finish .rc-steps-item-icon {
    background-color: $primery-color; }
  .rc-steps-label-vertical .rc-steps-item-content {
    width: 100px;
    position: relative;
    margin-top: 30px; }
  .rc-steps-item-finish .rc-steps-item-title {
    color: #8E8D8E;
    font-weight: normal; }
  .rc-steps-item-wait .rc-steps-item-title {
    color: #8E8D8E;
    font-weight: normal; }
  .rc-steps-item-wait .rc-steps-item-icon > .rc-steps-icon {
    color: #8E8D8E; }
  .rc-steps-item-process .rc-steps-item-title {
    color: $second-color;
    font-weight: normal; }
  .rc-steps-icon {
    top: 2px; }
  .rc-steps-item-title {
    width: 170px; }
  .t2, .t3, .t4 {
    .rc-steps-item-title {
      position: relative;
      left: -32px; } }
  .t5 {
    .rc-steps-item-title {
      position: relative;
      left: -72px; } }
  .t1,.t2, .t3, .t4, .t5 {
   .rcicon {
      &:before {
        height: 100%;
        width: 100%;
        position: relative;
        font-size: 14px;
        color: $white;
        top: 4px; } } }
  .t1 {
    .rcicon {
      &:before {
        content: '1'; } } }
  .t2 {
    .rcicon {
      &:before {
        content: '2'; } } }
  .t3 {
    .rcicon {
      &:before {
        content: '3'; } } }

  .t4 {
    .rcicon {
      &:before {
        content: '4'; } } }

  .t5 {
    .rcicon {
      &:before {
        content: '5'; } } } }

.settings-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .title-content {
    border-bottom: 1px solid #EEF0F4;
    margin-bottom: 50px;
    h4 {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 12px;
      line-height: 50px;
      color: $second-color;
      font-family: $font-second;
      padding-top: 10px; } }
  .list-part {
    justify-content: space-between;
    @include respond-to-custom(991px) {
      flex-direction: column; }
    .list-customer {
      list-style-type: none;
      padding: 0 100px 0 0;
      margin: 0;
      color: $second-color;
      font-size: 14px;
      line-height: 28px;
      font-family: $font-second;
      &:last-child {
        @include respond-to-custom(991px) {
          padding-right: 0; } }
      @include respond-to-custom(991px) {
        padding: 0 15px 0 0; }
      li {
        padding: 15px 0;
        span {
          cursor: pointer;
          margin-left: 20px;
          background-image: url("./img/Vector.png");
          height: 15px;
          width: 15px;
          background-size: cover;
          background-position: center;
          display: inline-flex; } } }

    .list-customer-key {
      color: #8E8D8E; } } }


@import './nutrition-page/total-information/atsio';
