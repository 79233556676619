@import '../../../sass/variables';
@import '../../../sass/mixin';
.chronotype-page .progenitor-page .h4 {
    @include respond-to-custom(576px) {
        text-align: center; } }
.progenitor-page {
    .d-b {
        display: block; }
    .h5 {
        font-size: 24px;
        margin-bottom: 20px;
        @include respond-to-custom(767px) {
            font-size: 18px; } }
    .grey-size {
        font-size: 14px; }
    .grey {
        color: #8E8D8E; }
    .your-progenitor {
        align-items: flex-start;
        padding-bottom: 80px;
        border-bottom: 1px solid #EEF0F4;
        @include respond-to-custom(1600px) {
            padding-bottom: 40px; }
        @include respond-to-custom(991px) {
            flex-direction: column; }
        @include respond-to-custom(991px) {
            padding-bottom: 20px; }

        .name {
            min-width: 258px;
            max-width: 258px;
            height: 275px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(196, 196, 196, .2);
            @include respond-to-custom(576px) {
                margin: 0 auto; } }


        .your-info {
            margin-left: 7%;
            @include respond-to-custom(1600px) {
                margin-left: 30px; }
            @include respond-to-custom(991px) {
                margin-left: 0;
                margin-top: 30px; }
            p {
                margin-top: 30px;
                @include respond-to-custom(576px) {
                    margin-top: 20px; } } } }
    .history {
        padding: 80px 0 60px;
        justify-content: space-between;
        position: relative;
        align-items: flex-start;
        border-bottom: 1px solid #EEF0F4;
        @include respond-to-custom(767px) {
            flex-direction: column-reverse;
            padding: 40px 0 0; }

        &:last-child {
            border-bottom: none; }
        .info {
            width: 40%;
            padding-right: 30px;
            @include respond-to-custom(767px) {
                width: 100%;
                padding-right: 0; }
            .desc {
                margin-bottom: 50px;
                @include respond-to-custom(576px) {
                    margin-bottom: 30px;
                    font-size: 14px; }
                &::after {
                    content: 'T1';
                    display: inline-block;
                    margin-left: 10px;
                    background: #F0F6FF;
                    border-radius: 99px;
                    padding: 3px 22px;
                    color: #1157CC; } }
            .block {
                padding-bottom: 50px;
                margin-bottom: 50px;
                border-bottom: 1px solid #EEF0F4;
                font-size: 14px;
                line-height: 26px;
                @include respond-to-custom(767px) {
                    padding-bottom: 20px;
                    margin-bottom: 35px; }
                &:last-child {
                    border-bottom: none;
                    padding-bottom: 0; } } }
        .map-section {
            position: sticky;
            top: 20px;
            width: 60%;
            @include respond-to-custom(991px) {
                top: 70px; }
            @include respond-to-custom(767px) {
                position: static;
                margin-bottom: 30px;
                width: 100%; }
            > img {
                width: 100%; }
            .map-tree {
                flex-wrap: wrap;
                .line-v {
                    height: 40px;
                    width: 1px;
                    margin: 0 auto; }
                .line-circle {
                    position: relative;
                    background: #FF8B8B;
                    &:before {
                        content: '';
                        display: block;
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        background: #FF8B8B;
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        box-shadow: 0px 0px 5px 2px rgba(255, 182, 139, 0.5); } }
                .person {
                    max-width: 188px;
                    width: 100%;
                    margin: 0 auto;
                    padding: 12px 10px;
                    text-align: center;
                    position: relative;
                    img {
                        margin-bottom: 10px; }
                    p {
                        margin: 0; } }
                .persons-block {
                    width: 100%;
                    justify-content: space-between; }
                .lines-block {
                    width: 50%;
                    margin: 0 auto;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    .line-g {
                        width: 100%;
                        height: 1px;
                        background: #FF8B8B; }
                    .line-v-m {
                        background: #FF8B8B;
                        width: 1px;
                        height: 30px;
                        &::before {
                            bottom: 0; } } } } } } }
