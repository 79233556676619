@import "../sass/mixin";
@import "../sass/variables";
@import "../sass/tools/anouther";
body {
  font-family: $font-stack; }
.simple-img {
  display: block;
  width: 100%;
  max-width: 1130px;
  margin-left: auto;
  margin-right: auto; }
.no-wrap {
  white-space: nowrap; }
.container {
  &--xl {
    max-width: 1360px;
    @include container-init(); }
  &--lg {
    max-width: 1160px;
    @include container-init(); }
  &--md {
    max-width: 930px;
    @include container-init(); } }

.blue {
  color: $blue!important; }
.white {
  color: white!important; }
.bold {
  font-weight: bold; }
.semi-bold {
  font-weight: 500; }
.paragraph {
  color: $dark-blue;
  font-size: 16px;
  line-height: 2;
  display: block;
  margin: 0 auto;
  @include respond-to-custom(625px) {
    font-size: 15px;
    line-height: 24px; }
  &-title {
    color: $second-color;
    display: block;
    width: 100%;
    font-size: 30px;
    margin-bottom: 40px;
    line-height: 1;
    @include respond-to(sm) {
      font-size: 26px; }
    @include respond-to(xss) {
      font-size: 22px; } }
  &.mb-0 {
    margin-bottom: 0; } }
.bg-gray {
  background-image: linear-gradient(66.68deg, #F1F7FF 0.4%, #FFFFFF 60.92%, #F5F8FF 142.71%); }
.figures {
  &-list {
    //.figures-list/
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    &-item {
      //.figures-list-item/
      margin: 0 0 70px 0;
      display: flex;
      align-items: flex-start;
      @include set-width(40%);
      @include respond-to(lg) {
        margin-bottom: 50px; }
      @include respond-to(md) {
        @include set-width(46%); }
      @include respond-to(sm) {
        margin-bottom: 30px; }
      @include respond-to(xs) {
        @include set-width(100%); }
      &__num {
        //.figures-list-item__num/
        display: block;
        @include set-width(35px);
        font-size: 30px;
        line-height: 1;
        color: $blue;
        margin-right: 23px;
        font-weight: 500;
        &:first-letter {
          color: #D9E6FF; } }
      &__desc {
        //.figures-list-item__desc/
        display: block;
        flex: 1;
        color: $second-color;
        font-size: 16px;
        line-height: 32px;
        font-weight: bold;
        transform: translateY(-4px);
        @include respond-to(md) {
          line-height: 1.2; } } } } }
.appeal-block {
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  padding: 120px 0;
  @include respond-to(md) {
    padding: 90px 0; }
  @include respond-to(sm) {
    padding: 70px 0; }
  @include respond-to(xs) {
    padding: 50px 0; } }
.gen-list {
  padding: 0;
  list-style-type: none;
  &-item {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 50px;
      @include respond-to(md) {
        margin-bottom: 30px; }
      @include respond-to(xss) {
        margin-bottom: 10px; } }
    &__icon {
      @include simple-icon(40px,40px);
      margin-right: 20px;
      margin-top: 5px;
      @include respond-to(xs) {
        @include set-size(26px,26px); } }
    &__desc {
      flex: 1;
      display: block;
      color: $second-color;
      font-weight: 700;
      font-size: 18px;
      line-height: 32px;
      font-family: $font-stack;
      @include respond-to(md) {
        font-size: 16px;
        line-height: 1.2; }
      @include respond-to(xs) {
        font-weight: 600; } } } }

.topBox {
  .wrapp-header {
    margin-top: 70px; }
  .wrapp-header:last-child {
    margin-top: 0; }
  .over-header-section {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999;
    &.dNone+header {
      @include respond-to-custom(576px) {
        margin-bottom: -60px; }
      .wrapp-header {
        margin-top: 0; } }
    @include respond-to-custom(991px) {
      &.dNone+header {
        .wrapp-header {
          top: 0;
          margin-top: 0; } } } }
  @include respond-to-custom(991px) {
    .wrapp-header {
      top: 70px;
      margin-top: 0; } } }
