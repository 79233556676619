@import '../sass/variables';

.wrapp-no-found {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  height: 75vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  .content-wrapped {
    h2 {
      font-size: 140px;
      line-height: 75px;
      font-family: 'Ubuntu Mono', monospace;
      padding: 40px 0;
      font-weight: bold;
      color: #0F85EE; }
    p {
      font-size: 42px;
      line-height: 32px;
      margin: 0;
      color: $second-color;
      font-weight: bold; } }

  .arr {
    img {
      transform: rotate(180deg); } } }


@media only screen and (max-width: 768px) {
  .wrapp-no-found {
    flex-direction: column;
    height: 100%;
    padding: 120px 0;

    .arr {
      img {
        transform: rotate(270deg); } } } }

