a, a:hover, button, button:hover, input, input:focus {
	transition: .3s; }
.cabinet-page {
	.title-content {
		h4 {
			margin-bottom: 0;
			text-transform: uppercase;
			font-weight: 500;
			font-size: 12px;
			line-height: 50px;
			color: #0E1658;
			font-family: "Roboto", sans-serif; } } }

.status-step {
	height: auto;


	.rc-steps-item:first-child {
		.rc-steps-item-title {
			text-align: left; } }
	.rc-steps-item:last-child {
		.rc-steps-item-title {
			text-align: right; } }

	.rc-steps-item-icon {
		display: flex;
		align-items: center;
		justify-content: center; } }

.cabinet-page .main-wrapp .row .sidebar .img-wrapped {
	margin: 0 auto;
	border-radius: 50%; }
.status-step .title-content {
	border: none;
	position: relative;
	&:after {
		content: '';
		display: block;
		position: absolute;
		background: #EEF0F4;
		height: 1px;
		width: calc(100% + 90px);
		left: -45px;
		bottom: 0; } }
.cabinet-page {
	font-family: 'Roboto', sans-serif;
	.main-wrapp {
		.row {
			.main-cont {
				.nawb-header {
					.title-wrapp {
						h2 {
							font-size: 20px; } } } } } }
	.title-wrapp {
		a {
			color: #1157CC; } }
	.sidebar-sticky {
		.menu-def {
			padding-top: 40px!important;
			p {
				margin: 6px 0; } }
		.nav {
			a {
				color: #0E1658;
				text-transform: uppercase;
				font-weight: 500;
				font-size: 12px;
				font-family: 'Roboto', sans-serif;
				position: relative;
				transition: .3s;
				line-height: 38px;
				text-decoration: none;
				&:hover {
					text-decoration: underline; } }

			.head-info {
				p {
					color: #8E8D8E;
					font-size: 12px;
					text-align: center; }

				h3 {
					font-size: 18px;
					color: #0E1658; } } }
		.nav > div:nth-child(5) {
			margin-bottom: 30px; }
		.nav > div:nth-child(5),
		.nav > div:nth-child(4) > a {
			color: #8E8D8E;
			text-transform: none;
			font-size: 14px;
			line-height: 28px;
			font-weight: normal;
			cursor: pointer;
			//text-decoration: underline

			&:hover {
				text-decoration: none; } } }

	.navbar.nawb-header {
		.button {
			margin-right: 0; } }

	.settings-info {
		.list-customer:not(.list-customer-key) > li:nth-child(6),
		.list-customer:not(.list-customer-key) > li:nth-child(7) {
			color: #1157CC; } }

	.rc-steps-item-tail {
		// outline: 1px solid #EEF0F4
		// outline-offset: 15px

		&:after {
			left: 20px; } }

	.rc-steps-item-icon {

		&::before {
			// content: '';
			// display: block
			// width: 67px
			// height: 67px
			// border-radius: 50%
			// background: none
			// border: 1px solid #EEF0F4
			// position: absolute
			// top: -15px
 } } }			// left: -15px
.settings-info,
.status-step,
.skin-passport-page .content-block,
.nutrition-page .navigation-content {
	background-color: #fff;
	box-shadow: 5px 8px 20px rgba(0, 0, 0, 0.03);
	border-radius: 5px;
	padding: 0 45px 50px 45px; }
.settings-info {
	@media screen and ( max-width: 991px ) {
		padding: 0 15px 30px; } }

.cabinet-page .main-wrapp .row .main-cont {
	background: none;

 }	// skin passport page
.skin-passport-page {
	color: #8E8D8E;

	.content-block {
		padding: 40px 40px 120px;
		background: #fff;
		display: flex;
		align-items: stretch;
		justify-content: space-between;
		margin-bottom: 30px;
		@media screen and ( max-width: 1280px ) {
			flex-direction: column; }
		@media screen and ( max-width: 576px ) {
			margin-bottom: 0;
			padding: 40px 15px 30px; }


		.left-column {
			flex-grow: 2;
			flex-basis: 0; }
		.right-column {
			flex-grow: 1.26;
			flex-basis: 0;
			@media screen and ( max-width: 576px ) {
				margin-top: 10px; } }

		h4 {
			font-weight: 500;
			font-size: 18px;
			color: #0E1658;
			margin-bottom: 45px;
			@media screen and ( max-width: 576px ) {
				text-align: center;
				margin-bottom: 30px; } }

		.features-block {

			ul {
				display: flex;
				flex-wrap: wrap;
				list-style: none;
				padding: 0;
				margin: 0;

				li {
					width: 50%;
					margin-bottom: 40px;
					padding-right: 20px;
					&:hover {
						color: #1157CC;
						cursor: pointer; }
					@media screen and ( max-width: 576px ) {
						width: 100%;
						padding-right: 0;
						margin-bottom: 30px;
						text-align: center; }
					p {
						margin: 0;
						font-size: 14px;
						line-height: 28px; }
					.skin-bar {
						max-width: 208px;
						width: 100%;
						height: 20px;
						background: #F0F6FF;
						border-radius: 99px;
						margin-top: 10px;
						position: relative;
						overflow: hidden;
						padding-left: 15px;
						@media screen and ( max-width: 576px ) {
							margin: 10px auto 0;
							max-width: 230px; }

						.skin-status {
							position: absolute;
							background: linear-gradient(277deg, #5263FF -1.9%, #32C1FF 106.61%);
							height: 100%;
							left: 0;
							top: 0;
							width: 0%;
							transition: 2s;
							font-size: 12px;
							color: #ffffff;
							display: flex;
							align-items: center;
							span {
								padding-left: 15px; } } } } } }

		.recomand-blocks {
			.recomand-block {
				margin-bottom: 70px;
				&:last-child {
					@media screen and ( max-width: 576px ) {
						margin-bottom: 0; } }
				@media screen and ( max-width: 576px ) {
					text-align: center;
					margin-bottom: 50px; }

				> div {
					background: #F0F6FF;
					border-radius: 5px;
					width: 65px;
					height: 65px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-bottom: 30px;
					@media screen and ( max-width: 576px ) {
						margin: 0 auto 30px; } }

				h5 {
					font-weight: bold;
					font-size: 14px;
					line-height: 22px;
					color: #0E1658;
					@media screen and ( max-width: 576px ) {
						text-align: center; } }

				p {
					font-size: 14px;
					line-height: 26px;
					display: flex;
					align-items: center;
					color: #0E1658; } } } }

 }	// nutrition page styles
.utrition-page-tab > .nav-link.active {
	font-weight: 500;
	font-size: 14px;
	color: #1157CC;
	border: none;
	border-bottom: 2px solid #1157CC;
	transition: .3s; }
.utrition-page-tab > .nav-link {
	border: none;
	color: #8E8D8E;
	font-size: 14px;
	font-weight: 500;
	border-bottom: 1px solid #EEF0F4;
	transition: .3s;
	padding: 19px 50px 20px;
	@media screen and ( max-width: 768px ) {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-left: 30px;
		padding-right: 30px; } }
.nutrition-page {
	width: 100%;
	.navigation-content {
		padding: 0;
		.block {
			padding: 50px 45px 100px; }
		.total-information {
			h5 {
				font-size: 18px;
				font-weight: 500;
				color: #0E1658; }
			.first-section {
				display: flex;
				margin-bottom: 80px;
				@media screen and ( max-width: 991px ) {
					flex-direction: column; }
				@media screen and ( max-width: 991px ) {
					margin-bottom: 40px; }
				> .d-flex {
					justify-content: space-between;
					margin-right: 50px;
					@media screen and ( max-width: 991px ) {
						margin-bottom: 30px; }
					@media screen and ( max-width: 576px ) {
						flex-direction: column; } }
				button {
					background: #1157CC;
					box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
					border-radius: 5px;
					max-width: 207px;
					width: 100%;
					height: 50px;
					color: #ffffff;
					text-transform: uppercase;
					font-weight: 500;
					font-size: 12px;
					outline: none;
					cursor: pointer;
					border: 1px solid #1157CC;

					&:hover {
						background: #fff;
						color: #1157CC; } }

				> div {
					width: 50%;
					@media screen and ( max-width: 991px ) {
						width: 100%; } }
				.calories-per-dey {
					flex-grow: 1;
					@media screen and ( max-width: 991px ) {
						align-items: center; } }
				.calories-count {
					max-width: 337px;
					width: 100%;
					margin-left: 30px;
					@media screen and ( max-width: 576px ) {
						max-width: 100%;
						margin-left: 0; }
					> p {
						font-size: 14px;
						line-height: 24px;
						color: #8E8D8E;
						span {
							font-weight: 500;
							color: #0E1658; } }

					ul {
						list-style: none;
						padding: 0;
						margin: 0;
						border: 1px solid #EEF0F4;
						li {
							font-size: 14px;
							line-height: 24px;
							display: flex;
							justify-content: space-between;
							align-items: center;
							padding: 11px 30px 11px;
							color: #0E1658;
							p:first-child {
								position: relative;
								padding-left: 19px;
								&:before {
									content: '';
									display: block;
									width: 8px;
									height: 8px;
									border-radius: 50%;
									position: absolute;
									left: 0;
									top: 7px; } }
							p {
								margin: 0; }
							.percent {
								color: #FD9C43;
								font-weight: bold; } }
						li:not(:last-child) {
							border-bottom: 1px solid #EEF0F4; }

						.title {
							color: #8E8D8E;
							padding: 13px 30px 14px;
							p:first-child {
								padding-left: 0; } }

						.protein {
							p:first-child {
								&:before {
									background-color: #E84C3D; } } }
						.fats {
							p:first-child {
								&:before {
									background-color: #43C738; } } }
						.carbohydrates {
							p:first-child {
								&:before {
									background-color: #FD9C43; } } } } }
				.calories-per-dey {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					@media screen and ( max-width: 576px ) {
						margin-bottom: 30px; }
					.calories-progress {
						display: flex;
						justify-content: center;
						max-width: 207px;
						position: relative;
						@media screen and ( max-width: 576px ) {
							margin-bottom: 30px; }
						canvas {
							z-index: 2; }
						.text-block {
							position: absolute;
							top: calc(50% - 20px);
							font-size: 12px;
							line-height: 14px;
							width: 80px;
							text-align: center;
							z-index: 1;
							.calc-number {
								color: #0E1658;
								font-size: 20px;
								font-weight: bold;
								margin-bottom: 6px; }
							p {
								margin: 0; } } } }


				.mass-index {
					position: relative;
					margin-right: 50px;
					padding-left: 40px;
					flex-wrap: wrap;
					> img {
						position: absolute;
						top: 8px;
						right: 50px;
						@media screen and ( max-width: 1100px ) {
							display: none; } }

					> p {
						font-size: 14px;
						line-height: 24px;
						color: #8E8D8E;
						width: 100%;
						@media screen and ( max-width: 576px ) {
							text-align: center; } }

					.count {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 114px;
						height: 114px;
						border-radius: 50%;
						border: 1px solid #EEF0F4;
						color: #FD9C43;
						font-size: 30px;
						font-weight: bold;
						background: #fff;
						margin-right: 30px; }

					.calculate-block {
						flex-grow: 1;
						form {
							display: flex;
							flex-direction: column;
							input {
								border: none;
								outline: none;
								border-bottom: 2px solid #B5C4DB;
								padding-bottom: 7px;
								padding-top: 3px;
								font-size: 14px;
								margin-bottom: 35px;
								max-width: 208px;
								width: 100%;
								@media screen and ( max-width: 991px ) {
									max-width: 100%; }
								&::placeholder {
									color: #B2C2DA; }
								&:focus {
									border-color: #0E1658;
									color: #0E1658; } }
							div {
								display: flex;
								justify-content: space-between;
								margin-top: 12px;
								.norms-btn {
									background: #fff;
									box-shadow: 5px 8px 20px rgba(0, 0, 0, 0.03);
									color: #1157CC;
									&:hover {
										background: #1157CC;
										color: #fff; } } } } } } }


			.information-block {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				margin-top: 30px;
				padding: 0;
				.item {
					width: 31%;
					display: flex;
					margin-bottom: 30px;
					font-size: 14px;
					line-height: 22px;
					@media screen and ( max-width: 1200px ) {
						width: 48%; }
					@media screen and ( max-width: 576px ) {
						width: 100%;
						align-items: center; }
					.icon__container {
						margin-right: 20px;
						min-width: 65px;
						width: 65px;
						height: 65px;
						background: #F0F6FF;
						border-radius: 5px;
						display: flex;
						align-items: center;
						justify-content: center; }

					.info {
						span,p {
							display: inline; }
						span {
							color: #0E1658;
							font-weight: bold; }
						p {
							color: #8E8D8E; } } } }
			.warn-products {
				width: 100%;
				height: 80px;
				display: flex;
				align-items: center;
				padding: 0 20px;
				padding-left: 74px;
				border: 1px solid #FF8E75;
				border-radius: 5px;
				margin-top: 10px;
				@media screen and ( max-width: 576px ) {
					height: auto;
					padding: 10px 15px; }
				img {
					margin-bottom: 3px;
					margin-right: 10px; }
				span,p {
					display: inline; }
				span {
					color: #0E1658;
					font-weight: bold; }
				p {
					color: #8E8D8E; } }

			.restrict-section {
				margin-top: 50px;
				@media screen and ( max-width: 576px ) {
					margin-top: 40px; }
				p {
					margin: 0; } }
			.healthy-plate-section {
				margin-top: 50px;
				h5 {
					margin-bottom: 40px; }

				.progress-plate {
					display: flex;
					padding: 0 80px;
					.plate-block {
						width: 25%;
						padding: 0 20px;
						position: relative;
						> p {
							position: absolute;
							top: 50%;
							color: #8E8D8E;
							font-size: 14px;
							text-align: center;
							margin: 0;
							margin-top: 10px;
							left: 50%;
							transform: translateX(-50%); }
						> div {
							border: 3px solid #F0F6FF;
							border-radius: 50%;
							padding: 10px; }

						.CircularProgressbar .CircularProgressbar-trail {
							background: #B5C4DB;
							stroke-width: 3; }
						.CircularProgressbar .CircularProgressbar-path {
							stroke-width: 3;
							stroke: #FFAB40; }

						.CircularProgressbar .CircularProgressbar-text {
							fill: #1157CC;
							transform: translateY(-7px); } } } } } } }
.calculate-block button:first-child {
		margin-right: 20px; }
@media only screen and (max-width: 1666px) {
	.nutrition-page .navigation-content .total-information .first-section .mass-index > img {
		right: 0; }
	.nutrition-page .navigation-content .total-information .first-section .mass-index {
		margin-right: 0; }
	.nutrition-page .navigation-content .total-information .healthy-plate-section .progress-plate {
		padding: 0; } }

@media only screen and (max-width: 1492px) {
	.nutrition-page .navigation-content .total-information .healthy-plate-section .progress-plate .plate-block {
		padding: 0 10px; }
	.nutrition-page .navigation-content .block {
		padding-left: 20px;
		padding-right: 20px; }
	.nutrition-page .navigation-content .total-information .first-section > .d-flex {
		margin-right: 0; }
	.nutrition-page .navigation-content .total-information .first-section .mass-index {
		padding-left: 20px; }
	.nutrition-page .navigation-content .total-information .first-section .mass-index .count {
		width: 90px;
		height: 90px;
		margin-right: 20px; }

	.nutrition-page .navigation-content .total-information .first-section button {
		max-width: 160px; } }
@media only screen and (max-width: 1300px) {
	.status-step .t5 .rc-steps-item-title,
	.status-step .t2 .rc-steps-item-title,
	.status-step .t3 .rc-steps-item-title,
	.status-step .t4 .rc-steps-item-title {
		left: 0;
		text-align: left;
		margin: 0;
		margin-left: 30px; }
	.status-step .rc-steps-item-title {
		width: 100%; }
	.status-step .rc-steps-item:first-child .rc-steps-item-title {
		margin: 0;
		margin-left: 30px; }

	.status-step .rc-steps-item:last-child .rc-steps-item-title {
		text-align: left; }

	.status-step .rc-steps-item-tail {
		width: 60px;
		margin: 0;
		top: 37px;
		left: -17.5px;

		&:after {
			width: 10px;
			height: 70px; } }

	.status-step {

		.rc-steps {
			flex-direction: column; }

		.rc-steps-item-container {
			display: flex;
			align-items: center; }


		.rc-steps-label-vertical {

			.rc-steps-item {
				margin-bottom: 70px; }

			.rc-steps-item-content {
				margin: 0;
				width: auto; } }

		.rc-steps-item-process {

			.rc-steps-item-icon {
				margin: 0; } }

		.rc-steps-item-wait {

			.rc-steps-item-icon {
				margin: 0; } } } }



@media only screen and (max-width: 1366px) {
	.section-seven .icon-left-arrow,
	.section-seven .icon-right-arrowt {
		font-size: 30px; }
	.custom-carousel-arrows {
		justify-content: space-between;
		top: calc(50% - 30px);
		right: auto;
		bottom: auto;
		left: auto;
		z-index: 8; }



	.popup {
		max-height: 100% !important;
		.popup__close {
			top: 43px;
			right: 10px;
			z-index: 9;
			path {
				fill: #1157CC; } } } }
@media screen and ( max-width: 991px ) {
	.nutrition-page .navigation-content .total-information .first-section button {
		max-width: 50%; } }
@media only screen and (max-width: 768px) {
	.nav-tabs {
		border-bottom: none;
		.nav-link {
			margin-bottom: 0; } } }
@media only screen and (max-width: 675px) {
	.nutrition-page .navigation-content .block {
		padding-left: 15px;
		padding-right: 15px;
		padding-bottom: 40px; }
	.custom-carousel-arrows {
		top: 40%;
		min-width: 116%;
		margin-left: -8%; }
	.player-video {
		width: 100% !important;
		max-width: 100%; }
	.popup {
		min-width: 85%;
		min-width: 85%;
		width: 85%;
		max-height: 100%; }
	.nutrition-page .navigation-content .total-information .first-section button {
		max-width: 100%; }
	.custom-carousel-arrows-item {
			width: 20px;
			min-width: 20px;
			max-width: 20px;
			flex: 0 20px;
			height: 20px;
			min-height: 20px;
			max-height: 20px;
			margin: 0; }
	.section-seven .icon-left-arrow,
	.section-seven .icon-right-arrowt {
		font-size: 16px; }

	.popup {
		.popup__close {
			transform: none;
			top: 10px;
			right: 10px; } } }
@media screen and ( max-width: 576px ) {
	.nutrition-page .navigation-content .total-information .first-section .mass-index {
		padding-left: 0; }
	.nutrition-page .navigation-content .total-information .first-section button {
		max-width: 230px; }
	.nutrition-page .navigation-content .total-information .first-section .mass-index .count {
		margin: 0 auto 25px; } }
@media only screen and (max-width: 425px) {
	.popup {
		.popup__close {
			top: 5px;
			right: 5px; } } }
