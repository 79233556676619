@import '../../../sass/variables';
@import '../../../sass/mixin';

.info-block {
    background-color: #fff;
    box-shadow: 5px 8px 20px rgba(0, 0, 0, 0.03);
    border-radius: 5px;
    padding: 20px 40px 30px;
    margin-bottom: 30px;
    @include respond-to-custom(1096px) {
        padding: 20px 15px; }
    &--img {
        display: block;
        width: 21px;
        height: 21px;
        margin-bottom: 15px;
        object-fit: contain; }
    p {
        font: 14px/22px $font-second;
        color: #8E8D8E; } }

