@import '../../../sass/variables';
@import '../../../sass/mixin';

.chronotype-page {
    background: #fff;
    width: 100%;
    ul {
        list-style: none; }
    .h3 {
        padding: 40px;
        font-size: 18px;
        font-weight: 500;
        color: #0E1658;
        border-bottom: 1px solid #EEF0F4;
        @include respond-to-custom(1096px) {
            padding: 20px 1rem; } }
    .h4 {
        font-size: 30px;
        margin-bottom: 30px;
        @include respond-to-custom(576px) {
            font-size: 21px;
            margin-bottom: 20px; } }
    .chronotype-wrapper {
        padding: 45px 40px;
        @include respond-to-custom(1096px) {
            padding: 20px 1rem; } }
    .with-text {
        p {
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 30px; } } }
.chronotype-wrapper {
    padding: 50px 40px;
    color: #0E1658;
    .ockclock-section {
        display: flex;
        justify-content: space-between;
        @include respond-to-custom(731px) {
            flex-direction: column;
            align-items: center; }
        .with-text {
            max-width: 930px;
            width: 100%;
            margin-right: 30px;
            @include respond-to-custom(731px) {
                margin-right: 0; }
            .clock-after-text {
                min-width: 157px;
                max-width: 157px;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                @include respond-to-custom(731px) {
                    margin: 0 auto 20px; }
                .time {
                    padding: 2px 20px;
                    width: 100%;
                    text-align: center;
                    font-size: 42px;
                    color: #1157CC;
                    background: #F0F6FF;
                    border-radius: 99px;
                    margin-bottom: 7px;
                    font-weight: 300; }
                .day-time {
                    font-size: 14px;
                    color: #8E8D8E; } } } }
    .lists {
        display: flex;
        padding-top: 50px;
        margin-top: 50px;
        border-top: 1px solid #EEF0F4;
        @include respond-to-custom(731px) {
            flex-direction: column; }
        .pic-block {
            min-width: 65px;
            width: 65px;
            height: 65px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            @include respond-to-custom(731px) {
                margin-bottom: 15px; } }
        .pic-block-bird {
            background: #E7FFF3; }
        .pic-block-owl {
            background: #F0F6FF; }
        .list {
            width: 50%;
            display: flex;
            align-items: flex-start;
            @include respond-to-custom(731px) {
                flex-direction: column;
                align-items: center;
                width: 100%; }
            &:first-child {
                margin-right: 30px;
                @include respond-to-custom(731px) {
                    margin-right: 0; } } }
        ul {
            @include respond-to-custom(1096px) {
                padding-left: 15px; }
            @include respond-to-custom(731px) {
                padding-left: 0; }
            li {
                margin-bottom: 10px;
                position: relative;
                display: flex;
                align-items: center;
                &:before {
                    content: '';
                    display: inline-block;
                    margin-right: 15px;
                    min-width: 8px;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: #1157CC; } }

            .title {
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 20px;
                padding-left: 0;
                @include respond-to-custom(731px) {
                    text-align: center;
                    display: block; }
                &:before {
                    display: none; } } } } }

@include respond-to-custom(371px) {
    .cabinet-page .navbar.nawb-header .button {
        margin: 0 auto; } }
