@import '../../../sass/variables';
@import '../../../sass/mixin';

.disease-page {
    .rc-pagination {
        margin-top: 50px;
        @include respond-to-custom(768px) {
            margin-top: 40px; } }
    .multi-risk {
        margin-bottom: 20px;
        @include respond-to-custom(991px) {
            flex-direction: column;
            align-items: flex-start;
            padding: 30px 20px; } }
    .link {
        border: 1px solid #1157CC;
        border-radius: 5px;
        width: 120px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        background: #fff;
        color: #1157CC;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        &:hover {
            text-decoration: none; } }
    p {
        margin: 0; }
    .with-border {
        border-right: 1px solid #EEF0F4;
        margin-right: 9%;
        @include respond-to-custom(1600px) {
            margin-right: 5%; }
        @include respond-to-custom(991px) {
            margin: 0;
            margin-bottom: 30px;
            border: none; } }

    .desc {
        color: #8E8D8E;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;
        display: block; }
    .block-box {
        width: 20%;
        @include respond-to-custom(991px) {
            width: 100%; } }

    .text {
        max-width: 525px;
        width: 100%;
        color: #0E1658;
        font-size: 14px;
        line-height: 26px;
        margin-right: 8%;
        @include respond-to-custom(1600px) {
            margin-right: 5%; }
        @include respond-to-custom(1440px) {
            max-width: 400px; }
        @include respond-to-custom(991px) {
            max-width: 100%;
            margin: 0;
            margin-bottom: 30px; } }
    .h4 {
        color: #1157CC;
        font-size: 20px;
        margin-bottom: 10px; }
    .title {
        color: #0E1658;
        font-size: 18px;
        font-weight: 500;
        margin-left: 10px; }
    .number {
        color: #0E1658;
        font-size: 36px; } }
