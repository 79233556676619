@import '../../../../sass/variables';
@import '../../../../sass/mixin';

.clock {
    border: 17px solid #F0F6FF;
    border-radius: 50%;
    .react-clock__face {
        background: linear-gradient(217.77deg, #41DDFF 6.95%, #358EE0 67.36%, #0B619F 134.41%);
        box-shadow: 0px 17px 20px rgba(60, 167, 214, 0.2);
        border: none; }
    .react-clock__mark__body {
        background-color: rgba(255, 255, 255, 0.4);
        height: 5px;
        top: 15px !important; }
    .react-clock__hour-mark__body {
        background: none;
        width: 0px;
        height: 0px;
        border-left: 2px solid transparent;
        border-right: 2px solid transparent;
        border-bottom: 2px solid transparent;
        border-top: 6px solid #fff; }
    .react-clock__mark__number {
        color: #fff;
        top: 10% !important;
        font-size: 14px; }
    .react-clock__mark:nth-child(3n) {
        .react-clock__hour-mark__body {
            border-top: 6px solid #7FDFAF; } }
    .react-clock__hour-hand__body {
        background: #fff;
        &:after {
            content: '';
            display: block;
            width: 1px;
            height: 10px;
            background: linear-gradient(58.01deg, #47C63C 13.24%, #CFFAD1 135.78%);
            position: absolute;
            top: 2px;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 5px; } }
    .react-clock__minute-hand__body {
        background: linear-gradient(307.66deg, #FFFFFF 2.32%, #D8F3FA 27.81%, #FFFFFF 52.79%, #D8F3FA 100.21%);
        &:after,
        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            transform: translateX(-50%); }
        &:after {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: linear-gradient(42.49deg, #47C63C 13.24%, #CFFAD1 135.78%);
            bottom: 7px;
            border: 1px solid #EAF8FC; }
        &:before {
            width: 4px;
            height: 14px;
            bottom: -4px;
            background: linear-gradient(307.66deg, #FFFFFF 2.32%, #D8F3FA 27.81%, #FFFFFF 52.79%, #D8F3FA 100.21%); } } }


