@import '../../../../sass/variables';
@import '../../../../sass/mixin';

.vitamins-container {
  display: flex;
  justify-content: space-between;
  .tab-content {
    display: block;
    width: 100%; }
  .h3 {
    font: 18px/26px $font-second;
    font-weight: 500;
    color: $second-color;
    margin-bottom: 20px; }
  .border-padding {
    border-bottom: 1px solid #EEF0F4;
    padding: 50px 0; }
  .color-one {
    .nav-link, .vitaminsTab-bar-line {
      background: linear-gradient(151.75deg, #FF6341 23.23%, #FFBC58 127.21%); } }
  .color-second {
    .nav-link, .vitaminsTab-bar-line {
      background: linear-gradient(217.66deg, #41DDFF 6.95%, #5F65F5 134.41%); } }
  .color-third {
    .nav-link, .vitaminsTab-bar-line {
      background: linear-gradient(139.04deg, #D4F576 23.23%, #3ABA4F 127.21%); } }
  .color-fourth {
    .nav-link, .vitaminsTab-bar-line {
      background: linear-gradient(118.61deg, #B741FF -19.19%, #327ACF 137.1%); } }
  .color-fifth {
    .nav-link, .vitaminsTab-bar-line {
      background: linear-gradient(139.04deg, #FFD850 23.23%, #FF9C09 127.21%); } }
  .color-sixth {
    .nav-link, .vitaminsTab-bar-line {
      background: linear-gradient(192.7deg, #FF74AE -44.19%, #FF2B2B 195.92%); } }
  .color-seventh {
    .nav-link, .vitaminsTab-bar-line {
      background: linear-gradient(313.78deg, #5263FF -1.9%, #32C1FF 106.61%); } }
  .color-eighth {
    .nav-link, .vitaminsTab-bar-line {
      background: linear-gradient(42.48deg, #47C63C 13.24%, #CFFAD1 135.78%);
      font-size: 12px; } }
  .color-ninth {
    .nav-link, .vitaminsTab-bar-line {
      background: linear-gradient(158.28deg, #FFF500 3.07%, #FF0000 153.49%);
      font-size: 8px; } } }

.vitaminsTab {
  margin-right: 100px;
  width: 100%;
  max-width: 106px;

  .nav-item {
    display: flex;
    justify-content: space-between; }
  .nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    width: 65px;
    height: 65px;
    margin-bottom: 20px;
    font: 24px/24px $font-second;
    font-weight: 500;
    color: #ffffff;
    text-transform: uppercase;

    &.active {
      width: 100%;
      + .vitaminsTab-bar {
        display: none; } } }

  &-bar {
    width: 15px;
    height: 65px;
    background: #F0F6FF;
    border-radius: 99px;
    display: flex;
    align-items: flex-end;
    &-line {
      width: 100%;
      border-radius: 0 0 99px 99px; } }
  &-content {
    width: 100%;
    .vitaminsTab-bar {
      width: 208px;
      height: 20px;
      align-items: flex-end;
      .vitaminsTab-bar-line {
        height: 100%;
        border-radius: 99px 0 0 99px; } }
    &-title {
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;
      border-bottom: 1px solid #EEF0F4;
      @include respond-to-custom(576px) {
        flex-direction: column; }
      &-name {
        @include respond-to-custom(576px);
        margin-bottom: 20px; }
      &-info {
        &-title {
          font: 14px/28px $font-second;
          color: $third-color;
          margin-bottom: 0; }
        &-desc {
          font: 14px/28px $font-second;
          font-weight: 500;
          color: $second-color;
          margin-bottom: 0; } } }
    &-description {
      display: flex;
      align-items: flex-start;
      @include respond-to-custom(991px) {
        flex-direction: column; }
      &-img {
        max-height: 260px;
        object-fit: contain;
        display: block;
        width: 100%;
        max-width: 330px;
        margin-right: 50px;
        @include respond-to-custom(991px) {
          margin-right: 0; } }
      &-text {
        font: 14px/22px $font-second;
        color: $second-color;
        margin-bottom: 16px;
        max-width: 1100px;
        @include respond-to-custom(576px) {
          font-size: 13px;
          line-height: 21px; }
        &:last-child {
          margin-bottom: 0; } } }
    &-list {
      display: flex;
      &-img {
        width: 65px;
        height: 65px;
        background: #E7FFF3;
        color: #7FDFAF;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 50px;
        @include respond-to-custom(576px) {
          margin-right: 10px;
          min-width: 40px;
          width: 40px;
          height: 40px; }
        span {
          display: inline-block;
          font-size: 21px;
          line-height: 1; } }
      &:first-child {
        .vitaminsTab-content-list-img {
          background: #FFE7E7;
          span {
            transform: rotate(180deg);
            color: #ff8b8b; } } }
      &-box {
        padding: 0;
        .h3 {
          line-height: 1; }
        li {
          list-style: none;
          padding-left: 24px;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 7px;
            width: 8px;
            height: 8px;
            background: #1157CC;
            border-radius: 50%; } } } }
    &-result {
      padding: 50px 0;
      ul {
        margin: 0;
        display: flex;
        justify-content: space-between;
        background: #fff;
        border-radius: 5px 5px 0 0;
        padding: 20px 50px;
        border: 1px solid #EEF0F4;
        @include respond-to-custom(576px) {
          padding: 20px 15px; }
        li {
          width: calc( 100% / 3 );
          display: block;
          margin-bottom: 0;
          text-align: center;
          &:first-child {
            text-align: left; }
          &:last-child {
            text-align: right; } }
        &:first-of-type {
          background: #f0f6ff; }
        &:last-of-type {
          border-radius: 0 0 5px 5px; } } }
    &-recommendation {
      border: 1px solid #1157CC;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 30px 50px;
      @include respond-to-custom(576px) {
        padding: 30px 20px; }
      .h3 {
        color: #1157CC;
        span {
          display: inline-block; }
        .icon-like {
          font-size: 20px;
          line-height: 1;
          margin-right: 15px; } } } } }




/* Icon Fonts */
@font-face {
  font-family: 'icomoon';
  src: url('./../../../../iconFonts/icomoon.eot?2qqu3');
  src: url('./../../../../iconFonts/icomoon.eot?2qqu3#iefix') format('embedded-opentype'), url('./../../../../iconFonts/icomoon.ttf?2qqu3') format('truetype'), url('./../../../../iconFonts/icomoon.woff?2qqu3') format('woff'), url('./../../../../iconFonts/icomoon.svg?2qqu3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
.icon-up:before {
  content: "\e90a"; }
.icon-like:before {
  content: "\e90b"; }
.icon-plus:before {
  content: "\e90c"; }
.icon-minus:before {
  content: "\e90d"; }

