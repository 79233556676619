@import '../sass/variables';
.wrapp-error {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  height: 80vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  h2 {
    font-size: 50px;
    line-height: 65px;
    font-family: $font-stack;
    text-align: center;
    padding: 40px 0;
    font-weight: bold;
    color: $primery-color; } }
