@import '../../../../sass/variables';
@import '../../../../sass/mixin';

.disease-page {
    .multi-risk {
        &--update {
            display: block;
            .text {
                width: 100%;
                max-width: 100%;
                margin-bottom: 45px; } }
        &-table {
            &-box {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                padding-left: 0;
                margin: 0;
                padding-bottom: 20px;
                margin-bottom: 20px;
                border-bottom: 1px solid #EEF0F4;
                &:first-child ,
                &:last-child {
                    margin-bottom: 0;
                    padding-bottom: 0;
                    border: none; } }
            .title-box {
                padding-bottom: 20px; }
            &-title {
                width: 25%;
                font: 14px/1.2 $font-second;
                font-weight: 500;
                color: #8E8D8E;
                list-style: none; }
            .mutation,
            .genotype,
            .risk,
            .norm {
                color: #0E1658;
                font-size: 18px;
                line-height: 25px;
                @include respond-to-custom(576px) {
                    font-size: 15px; } }
            .genotype {
                color: #1157CC;
                font-weight: bold; }
            .norm,
            .risk {
                color: #0E1658; } } } }

@include respond-to-custom(576px) {
    .disease-page {
        .multi-risk-table-title {
            width: 100%; }
        .multi-risk-table-box {
            border: none;
            padding-bottom: 0;
            margin-bottom: 25px; }
        .navigation-content {
            .block {
                padding-left: 0;
                padding-right: 0; } }
        .title {
            font-size: 15px; }
        .h4 {
            margin: 0;
            font-size: 18px; }
        .multi-risk--update {
            padding: 0;
            margin: 0;
            .text {
                margin: 0; } }
        .multi-risk-table {
            .title-box {
                padding: 0;
                width: 45%; }
            > div {
                margin-top: 20px;
                padding-bottom: 20px;
                border-bottom: 1px solid #EEF0F4;
                padding-left: 15px;
                padding-right: 15px;
                &:last-child {
                    border: none; }
                .d-flex {
                    margin-bottom: 5px;
                    &:last-child {
                        margin-bottom: 0; } } } } }

    // mobile acordeon styles
    .accordion__button {
        position: relative;
        cursor: pointer;
        user-select: none;
        padding: 20px 40px 20px 15px;
        &:focus {
            outline: none; }
        &:before {
            display: block;
            content: "";
            height: 10px;
            width: 10px;
            border-bottom: 2px solid;
            border-right: 2px solid;
            border-color: #0e1658;
            position: absolute;
            right: 15px;
            top: 42%;
            transform: rotate(-135deg) translateY(-50%); }
        &[aria-expanded=true]:before,
        &[aria-selected=true]:before {
            transform: rotate(45deg) translateY(-50%);
            right: 22px; } } }
