@import '../../../sass/variables';
@import '../../../sass/mixin';

.pharmocogenetics-page {
	min-height: 100vh;
	padding-bottom: 100px; }
.pharmocogenetics-content {
	ul {
		list-style: none;
		padding: 0;
		margin: 0; }
	p {
		margin: 0; }
	.gens-main-title {
		width: 20%;
		display: flex;
		align-items: center;
		border-right: 1px solid #EEF0F4;
		padding: 10px 25px 10px 30px; }
	.utrition-page-tab {
		> .nav-link {
			padding: 19px 30px 20px; } }
	.block {
		border: 1px solid #EEF0F4;
		border-top: none;
		.gens-main-title {
			color: #0E1658;
			font-size: 14px;
			line-height: 22px; }
		> ul {
			display: flex; }
		.check-list {
			width: 80%;
			>ul {
				display: flex;
				justify-content: space-between;
				height: 100%;
				li {
					width: 11.11%;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 12px;
					border-right: 1px solid #EEF0F4;
					&:last-child {
						border: none; }
					span {
						display: block;
						width: 36px;
						height: 36px;
						border: 1px solid #EEF0F4;
						border-radius: 50%; } }
				.active {
					span {
						border: none;
						background: #FFE7E7;
						position: relative;
						&:after,
						&:before {
							content: '';
							display: block;
							border-radius: 5px;
							position: absolute;
							width: 17px;
							height: 2px;
							background: #FF8B8B;
							left: 50%;
							top: 50%;
							transform: translate(-50%,-50%) rotate(45deg); }
						&:before {
							transform: translate(-50%,-50%) rotate(-45deg); } } } } } } }


