@import '../../../../sass/variables';
@import '../../../../sass/mixin';

.habits-content {
    .vitaminsTab-content-list-img {
        color: #FF8B8B;
        background: #FFE7E7; }
    .plus {
    	background: #e7fff3;
	    color: #7fdfaf; } }
