@import "mixin";
@import "variables";
.rc-pagination {
  //.rc-pagination/
  display: flex;
  justify-content: center;
  @include set-width(100%);
  margin: 0;
  padding: 0;
  font-family: $font-stack;
  li {
    //.rc-pagination li/
    margin-right: 10px;
    outline: none!important;
    &:last-child {
      //.rc-pagination li:last-child
      margin-right: 0; }
    display: block;
    @include set-size(35px,35px);
    @include select-none();
    @include respond-to-custom(500px) {
      @include set-size(30px,30px); }
    @include respond-to(xss) {
      @include set-size(24px,24px); }
    &[aria-disabled="true"] {
      //.rc-pagination li[aria-disabled="true"]
      a:hover {
        //.rc-pagination li[aria-disabled="true"] a:hover
        color: $third-color!important;
        border-color: $gray;
        cursor: not-allowed; } }
    &[class="rc-pagination-jump-next"],&[class="rc-pagination-jump-prev"] {
      a {
        &::before {
          content: '...';
          color: inherit;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          padding-bottom: 2px;
          padding-left: 2px; } } }
    a,.rc-pagination-item-link {
      //.rc-pagination li a/
      @include flex-center();
      @include set-size(100%,100%);
      color: $gray!important;
      border: 1px solid $gray;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      background-color: white;
      outline: none!important;
      &:hover {
        border-color: $primery-color;
        color: $primery-color!important; } }
    &.rc-pagination-item-active {
      //.rc-pagination li.rc-pagination-item-active
      a {
        //.rc-pagination li.rc-pagination-item-active a
        background-color: white;
        border-color: $primery-color;
        color: $primery-color!important; } } }
  &-prev {
    //.rc-pagination-prev/
    @include respond-from-custom(500px) {
      margin-right: 25px!important; }
    .rc-pagination-item-link {
      //.rc-pagination-prev .rc-pagination-item-link
      padding: 0 2px 0 0!important;
      svg {
        @include simple-icon(6px,12px); } } }
  &-next {
    //.rc-pagination-next
    @include respond-from-custom(500px) {
      margin-left: 15px!important; }
    .rc-pagination-item-link {
      //.rc-pagination-next .rc-pagination-item-link
      padding: 0 0 0 2px!important;
      svg {
        @include simple-icon(6px,12px); } } } }



