@import '../../../sass/variables';
@import '../../../sass/mixin';

.psychogenetic-risks {
    background: #fff;
    box-shadow: 5px 8px 20px rgba(0, 0, 0, 0.03);
    border-radius: 5px;
    padding: 40px 40px 50px;
    width: 100%;
    @include respond-to-custom(991px) {
        padding: 30px 15px 50px; }
    .rc-pagination {
        margin-top: 50px; }
    .h3 {
        color: #0E1658;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 40px;
        @include respond-to-custom(991px) {
            margin-bottom: 20px; } }
    .multi-risk {
        margin-bottom: 20px;
        display: block;
        @include respond-to-custom(1440px) {
            padding: 30px 20px 30px; }
        ul {
            list-style: none;
            display: flex;
            padding: 0;
            margin: 0;
            width: 100%;
            @include respond-to-custom(1280px) {
                flex-direction: column; }
            li {
                width: 16%;
                margin-right: 35px;
                @include respond-to-custom(1440px) {
                    margin-right: 20px; }
                @include respond-to-custom(1280px) {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 30px; }
                p {
                    color: #0E1658;
                    font-size: 14px;
                    line-height: 22px;
                    margin: 0; }
                .gen {
                    color: #1157CC;
                    font-size: 36px;
                    line-height: 36px; } }
            .last {
                width: 36%;
                @include respond-to-custom(1280px) {
                    width: 100%; } }
            span {
                color: #8E8D8E;
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 25px;
                display: block;
                @include respond-to-custom(1280px) {
                    margin-bottom: 5px; } } } } }
