@import '../../../../sass/variables';
@import '../../../../sass/mixin';

.ethnic-section {
    padding: 80px 0;
    border-bottom: 1px solid #EEF0F4;
    @include respond-to-custom(1600px) {
        padding: 40px 0; }
    @include respond-to-custom(576px) {
        padding: 30px 0; }
    .chartjs-render-monitor {
        @include respond-to-custom(1366px) {
            width: 218px !important;
            height: 218px !important; }
        @include respond-to-custom(1366px) {
            width: 260px !important;
            height: 260px !important; } }
    .content {
        @include respond-to-custom(991px) {
            flex-direction: column;
            align-items: flex-start !important; } }
    ul {
        padding: 0;
        margin: 0; }
    .tab-block {
        max-width: 555px;
        width: 100%;
        border: 1px solid #EEF0F4;
        border-radius: 5px;
        box-shadow: 5px 8px 20px rgba(0, 0, 0, 0.03);
        margin-right: 8%;
        @include respond-to-custom(1600px) {
            max-width: 400px;
            margin-right: 30px; }
        @include respond-to-custom(1366px) {
            max-width: 300px; }
        @include respond-to-custom(991px) {
            max-width: 555px;
            margin-bottom: 30px; }
        li {
            width: 100%;
            padding: 15px 30px 15px 45px;
            border-bottom: 1px solid #EEF0F4;
            @include respond-to-custom(1366px) {
                padding: 10px 20px 10px 40px;
                font-size: 15px; }
            &:last-child {
                border: none; }
            span {
                color: #0E1658; } }
        .title {
            font-size: 18px;
            position: relative;
            @include respond-to-custom(1366px) {
                font-size: 16px; }
            &:before {
                content: '';
                display: block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #1157CC;
                position: absolute;
                left: 20px;
                top: 50%;
                transform: translateY(-50%);
                @include respond-to-custom(1366px) {
                    left: 16px; } }
            span {
                color: #1157CC;
                font-weight: 500; } } }
    .chart-item {
        position: relative;
        z-index: 0;
        @include respond-to-custom(576px) {
            margin-bottom: 30px; }
        .number {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            z-index: -1;
            p {
                margin: 0; }
            .percent {
                font-size: 36px;
                @include respond-to-custom(1366px) {
                    font-size: 26px; } } } }
    .chart-block {
        max-width: 600px;
        width: 100%;
        @include respond-to-custom(576px) {
            flex-direction: column; }
        ul {
            margin-left: 50px;
            width: 100%;
            max-width: 280px;
            @include respond-to-custom(1600px) {
                margin-left: 30px; }
            @include respond-to-custom(576px) {
                margin-left: 0; }
            li {
                border: 1px solid #EEF0F4;
                border-radius: 99px;
                display: flex;
                align-items: center;
                min-height: 56px;
                padding: 6px 15px 6px 6px;
                margin-bottom: 20px;
                width: 100%;
                @include respond-to-custom(576px) {
                    font-size: 15px; }
                span {
                    min-width: 44px;
                    width: 44px;
                    height: 44px;
                    border-radius: 50%;
                    color: #ffff;
                    margin-right: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center; } }
            li:first-child {
                span {
                    background: #FF8B8B; } }
            li:nth-child(2) {
                span {
                    background: #636E8F; } }
            li:last-child {
                margin-bottom: 0;
                span {
                    background: #29B5E3; } } } } }
