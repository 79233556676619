@import '../mixin';
.icon-spinner {
  //.icon-spinner/
  animation: 1s spin infinite linear;
  transform-origin: center;
  &:not(.is--static) {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%); }
  &.is--white {
    //.icon-spinner.is--white/
    path {
      fill: white; } } }


