@mixin respond-to($media) {
  // width < 1199
  @if $media == lg {
    @media only screen and (max-width: 1199px) {
      @content; } }


  // width < 991
  @if $media == md {
    @media only screen and (max-width: 991px) {
      @content; } }


  // width < 767
  @if $media == sm {
    @media only screen and (max-width: 767px) {
      @content; } }


  // width < 575
  @if $media == xs {
    @media only screen and (max-width: 575px) {
      @content; } }


  // width < 400
  @if $media == xss {
    @media only screen and (max-width: 400px) {
      @content; } } }



@mixin respond-from($media) {
  // width >= 1200
  @if $media == lg {
    @media only screen and (min-width: 1200px) {
      @content; } }


  // width >= 992
  @if $media == md {
    @media only screen and (min-width: 992px) {
      @content; } }


  // width >= 768
  @if $media == sm {
    @media only screen and (min-width: 768px) {
      @content; } }


  // width >= 575
  @if $media == xs {
    @media only screen and (min-width: 575px) {
      @content; } }


  // width >= 400
  @if $media == xss {
    @media only screen and (min-width: 400px) {
      @content; } } }



@mixin respond-max-min($max,$min) {
  @media only screen and (max-width: $max) and (min-width: $min) {
    @content; } }

@mixin respond-landscape() {
  @media (orientation: landscape) {
    @content; } }

@mixin respond-to-landscape($max) {
  @media (orientation: landscape) and (max-width: $max) {
    @content; } }

@mixin respond-to-custom($max) {
  @media only screen and (max-width: $max) {
    @content; } }


@mixin respond-from-custom($min) {
  @media only screen and (min-width: $min) {
    @content; } }



@mixin transition() {
  transition: .3s ease-out; }


@mixin transition-custom($sek) {
  transition: $sek ease-out; }


@mixin flex-center() {
  display: flex;
  justify-content: center;
  align-items: center; }

@mixin set-height($height) {
  height: $height;
  min-height: $height;
  max-height: $height; }

@mixin set-width($width) {
  width: $width;
  min-width: $width;
  max-width: $width;
  flex: 0 $width; }


@mixin set-size($width, $height) {
  @include set-width($width);
  @include set-height($height); }

@mixin container-init() {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%; }

@mixin simple-icon($width, $height) {
  display: block;
  @include set-width($width);
  @include set-height($height); }

@mixin select-none() {
  user-select: none!important; }

@mixin text-ellipsis() {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%; }

@mixin placeholder() {
  color: #1d1f1f;
  opacity: 0.5;
  font-family: $font-stack;
  font-weight: 400; }
@mixin placeholderTwo() {
  color: #0E1658;
  opacity: 1;
  font: 16px/1 $font-second;
  font-weight: 400;
  font-style: italic; }
@mixin transition() {
  transition: all 0.3s ease-out; }


@mixin initial-bg() {
  position: absolute;
  left: 0;
  bottom: -10%;
  @include simple-icon(100%,110%);
  opacity: .4; }

@mixin placeholder-custom() {
  &::-webkit-input-placeholder {
    @content; }


  &::-moz-placeholder {
    @content; }


  &:-ms-input-placeholder {
    @content; }


  &:-moz-placeholder {
    @content; } }


@mixin appearance-none() {
  -webkit-appearance: none!important;
  -moz-appearance: none!important;
  appearance: none!important;
  outline: none!important;
  border: none;
  background-color: transparent;
  -moz-appearance: textfield;
  appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none; } }


@mixin letter-space() {
  letter-spacing: 0.075em; }


//---- Keyframes style

@keyframes spin {
  from {
    transform: rotate(0); }
  to {
    transform: rotate(360deg); } }

@mixin flex-c {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap; }
